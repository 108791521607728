import $ from 'jquery';

import Swiper, { Navigation, Pagination, Controller, Thumbs, Autoplay, Keyboard, Lazy } from 'swiper';

Swiper.use([Navigation, Pagination, Controller, Thumbs, Autoplay, Keyboard, Lazy ]);

export default class SwiperSlider {
  init() {
    $('[data-swiper]:not(.swiper-container-initialized)').not('.swiper-container-custom').each((i, el) => {
      const slider = $(el);
      const sliderSlides = $(el).find('.swiper-slide');
      const $nextEl = slider.parent().find('.swiper-button-next');
      const $prevEl = slider.parent().find('.swiper-button-prev');
      const $pagination = slider.find('.swiper-pagination');
      const items = slider.data('slide') || 1;
      const space = slider.data('space') || 0;
      const mdBreakPoint = slider.data('md') || 1;
      const mobileBreakPoint = slider.data('mobile') || 1;
      const autoplay = slider.data('autoplay') || false;
      const autoplayDelay = slider.data('autoplay-delay') || false;
      const pauseOnHover = slider.data('pause-on-hover') || false;
      const loop = slider.data('loop') || false;
      const swiper = new Swiper(el, {
        slidesPerView: items,
        autoplay: { 
          delay: autoplayDelay,
          pauseOnMouseEnter: pauseOnHover,
          disableOnInteraction: false
        },
        spaceBetween: space,
        slidesPerGroup: items,
        loop: loop,
        lazy: {
          loadPrevNext: true,
        },
        pagination: {
          el: $pagination[0],
          clickable: true,
        },
        navigation: {
          nextEl: $nextEl[0],
          prevEl: $prevEl[0],
        },
        breakpoints: {
          0: {
            slidesPerView: mobileBreakPoint,
            slidesPerGroup: mobileBreakPoint,
          },
          768: {
            slidesPerView: mdBreakPoint,
            slidesPerGroup: mdBreakPoint,
          },
          1024: {
            slidesPerView: items,
            slidesPerGroup: items,
          }
        },
      });
      if(!autoplay) {
        swiper.autoplay.stop();
      }
    });
    if($('.product-miniature-slider')) {
      const productMiniatureSlider = new Swiper(".product-miniature-slider", {
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 2
        }
      });
    }
  }
  imgslider() {
    $("#product-modal").on('show.bs.modal', function() {
      setTimeout(function() {
        const modalGallery = new Swiper(".js-modal-gallery", {
          observer: true,
          watchOverflow: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          preventInteractionOnTransition: true,
          spaceBetween: 10,
          keyboard: {
            enabled: true,
            onlyInViewport: false,
          },
          navigation: {
            nextEl: '.product-images-modal__button--next',
            prevEl: '.product-images-modal__button--prev',
          }
        });
        let dataSwiperIndex = galleryMain.activeIndex;
        if($('.js-modal-gallery').length) {
          modalGallery.slideTo(dataSwiperIndex);
        }
      }, 500);
    });

    const galleryThumbs = new Swiper(".product-gallery-js", {
      slidesPerView: 4,
      watchOverflow: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      breakpoints: {
        0: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        }
      },
    });
    
    const galleryMain = new Swiper(".product-cover-js", {
      watchOverflow: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      preventInteractionOnTransition: true,
      thumbs: {
        swiper: galleryThumbs
      }
    });
    
    $('.product-cover').on('slideChangeTransitionStart', function() {
      galleryThumbs.slideTo(galleryMain.activeIndex);
    });
    
    $('.js-qv-mask').on('transitionStart', function(){
      galleryMain.slideTo(galleryThumbs.activeIndex);
    });
  }
}