export default class RolloverImages {
	init = () => {
		$('.product-miniature-thumbnail ').each(function()
		{
			let newSrc = $(this).find('.rollover-images').data('rollover');
			if(newSrc == 0) return;
			let oldSrc;
			$(this).on("mouseover", function() {
				oldSrc = $(this).find('.rollover-source').attr('srcset');
				$(this).find('.rollover-source').attr('srcset', newSrc).stop(true,true);
			}), 
			$(this).on('mouseout', function() {
				$(this).find('.rollover-source').attr('srcset', oldSrc).stop(true,true);
			});
		});
	}
}