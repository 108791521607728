
export default class OffCanvas {

  offCanvasInit = (offcanvasBtn, offcanvasWrapper, offcanvasCloseBtn) => {
    const offCanvasOpenClass = 'offcanvas-open';
    const noscrollClass = 'noscroll';
    const offCanvasOverlayOpenClass = 'overlay-offcanvas-open'
    const offCanvasOverlay = $('.overlay-offcanvas');
    const offCanvasOverlayClass = '.overlay-offcanvas'
    const body = $('body');

    $(document).on('click', offcanvasBtn, () => {
      $(offcanvasWrapper).toggleClass(offCanvasOpenClass);
      offCanvasOverlay.toggleClass(offCanvasOverlayOpenClass);
      body.toggleClass(noscrollClass);
    });
    $(document).on('click', offcanvasCloseBtn, () => {
      $(offcanvasWrapper).removeClass(offCanvasOpenClass);
      offCanvasOverlay.removeClass(offCanvasOverlayOpenClass);
      body.removeClass(noscrollClass);
      body.removeClass('cart-open');
    });
    $(document).on('click', offCanvasOverlayClass, () => {
      $(offcanvasWrapper).removeClass(offCanvasOpenClass);
      offCanvasOverlay.removeClass(offCanvasOverlayOpenClass);
      body.removeClass(noscrollClass);
      body.removeClass('cart-open');
    });
  }
}
